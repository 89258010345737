import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import MetaImage from '../../assets/images/dx-meta.png'
import AllWorkIcon from '../../assets/images/product/blurb-icons/all-work.png'
import GearIcon from '../../assets/images/product/blurb-icons/gear.png'
import LockIcon from '../../assets/images/product/blurb-icons/lock.png'
import SurveysIcon from '../../assets/images/icons/surveys.svg'
import DataIcon from '../../assets/images/icons/bar-chart.svg'
import ResearchIcon from '../../assets/images/icons/research.svg'
import FAQ from '../../components/EnterpriseFAQ'
import Layout from '../../components/Layout'
import CTABlock from '../../components/blocks/CTABlock'
import ColumnsBlock from '../../components/blocks/ColumnsBlock'
import HeroBlock from '../../components/blocks/HeroBlock'
import LeadBlock from '../../components/blocks/LeadBlock'
import MosaicBlock from '../../components/blocks/MosaicBlock'
import QuoteBlock from '../../components/blocks/QuoteBlock'
import { FeaturedBlogArticles } from '../../components/changelog/FeaturedBlogArticles'
import Box from '../../components/primitives/Box'
import Blurb from '../../components/system/Blurb'
import { responsiveScale } from '../../styles/helpers'
import { DarkLogoBlock } from '../../components/blocks/DarkLogoBlock'

export const query = graphql`
  {
    backgroundImage: file(
      relativePath: { eq: "images/product/overview/hero-bg.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 99)
      }
    }
    mosaicLeft: file(relativePath: { eq: "images/mosaic/granular-data.png" }) {
      childImageSharp {
        gatsbyImageData(width: 680)
      }
    }
    mosaicRight: file(relativePath: { eq: "images/mosaic/survey-design.png" }) {
      childImageSharp {
        gatsbyImageData(width: 680)
      }
    }
    mosaicLeft2: file(relativePath: { eq: "images/mosaic/data-cloud.png" }) {
      childImageSharp {
        gatsbyImageData(width: 680)
      }
    }
    overview: file(relativePath: { eq: "images/enterprise/overview.png" }) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    integrations: file(
      relativePath: { eq: "images/enterprise/integrations.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    organizationalInsights: file(
      relativePath: {
        eq: "images/product/overview/organizational-insights.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 632)
      }
    }
    deploymentInsights: file(
      relativePath: { eq: "images/product/overview/deployment-insights.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 632)
      }
    }
    pinpointIssues: file(
      relativePath: { eq: "images/product/overview/pinpoint-issues.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    }
  }
`

const DXPage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout
      title="DX vs. Swarmia"
      variant="dark"
      isNew
      description="Explore the main differences between DX and Swarmia to find out which tool is a better fit for your engineering organization."
      metaImage={MetaImage}
    >
      <HeroBlock
        title={<>DX vs. Swarmia</>}
        content="DX does a great job in surfacing developer feedback through surveys. However, if you’re interested in improving not only developer experience but also business outcomes and developer productivity, Swarmia is probably a better fit."
        backgroundImage={getImage(data.backgroundImage)!}
      />
      <Box backgroundColor="black" paddingY={40} paddingX={{ xs: 20, md: 36 }}>
        <DarkLogoBlock hideBackground />
      </Box>
      <LeadBlock
        heading={<>What do Swarmia and DX have&nbsp;in&nbsp;common?</>}
        content="Both Swarmia and DX allow you to run engineering surveys and use those insights to systematically improve developer experience."
      />
      <ColumnsBlock
        paddingTop={responsiveScale(64)}
        paddingBottom={responsiveScale(64)}
        columns={[
          <Blurb
            key="1"
            title="Run developer experience surveys"
            text="Both Swarmia and DX make it easy to run recurring developer surveys and collect valuable feedback from teams."
            icon={SurveysIcon}
          />,
          <Blurb
            key="2"
            title="Export data to your own systems"
            text="Both tools allow you to export data to your data warehouse of choice for custom reporting and analysis."
            icon={DataIcon}
          />,
          <Blurb
            key="3"
            title="Based on research"
            text="Like DX, Swarmia has partnered with experts in psychometrics and developer productivity to build the product."
            icon={ResearchIcon}
          />,
        ]}
      />
      <QuoteBlock person="dave" />
      <LeadBlock
        heading="Why engineering leaders choose Swarmia over DX"
        content="We can all agree that surveys are a great source of developer feedback. However, in addition to survey data, Swarmia surfaces detailed insights ranging from developer productivity metrics to cost capitalization reports, giving you a holistic view of engineering effectiveness. "
      />
      <MosaicBlock
        title={<>Complement survey data with rich engineering insights</>}
        content={
          <>
            While we agree with DX that developers often have a good grasp on
            the things that are slowing them down, we look at surveys as one of
            many valuable data sources. Once you have your survey results, you
            can use Swarmia to drill into the specific problem areas and find
            the answers you need to take action.
            <br />
            <br />
            Swarmia combines survey data with healthy developer productivity
            metrics as well as powerful insights on business outcomes, so you
            can quickly gain a more holistic view of engineering effectiveness
            and make better decisions off the back of that information.
          </>
        }
        image={getImage(data.mosaicLeft)!}
      />
      <MosaicBlock
        title="Minimize noise and bias with thoughtful survey design"
        content={
          <>
            For all their talk about being research-backed, DX has made some
            interesting choices when it comes to survey design.
            <br />
            <br />
            At Swarmia, on the other hand, we’ve designed our survey framework
            with the help of psychometrics experts to make sure all our survey
            statements are understandable, particular, contextual, objective,
            and tangible — improving the quality of the collected data.
          </>
        }
        image={getImage(data.mosaicRight)!}
        imageAlign="right"
      />
      <MosaicBlock
        title={<>Bring granular data wherever&nbsp;you need it</>}
        content={
          <>
            DX’s Data Cloud allows you to export some raw data from your
            developer tools. However, there’s no UI for drilling into the data
            or an easy way to correlate data from multiple sources.
            <br />
            <br />
            Swarmia, on the other hand, ingests all your engineering data, helps
            you clean it up, offers meaningful visualizations, keeps the data in
            sync, and allows you to export it to your data warehouse of choice,
            whether that’s BigQuery, Snowflake, or Redshift.
          </>
        }
        image={getImage(data.mosaicLeft2)!}
      />
      <QuoteBlock person="maryanna" />
      <LeadBlock
        heading="Enterprise-grade features meet lightweight setup"
        content="Swarmia combines the best of both worlds: enterprise-grade features with flexible self-service configuration that only takes minutes."
      />
      <ColumnsBlock
        paddingTop={responsiveScale(48)}
        paddingBottom={responsiveScale(48)}
        columns={[
          <Blurb
            key="1"
            title="Built to scale"
            text="From team hierarchies to role-based access and export APIs, Swarmia fits the needs of large organizations."
            isLarge
            image={getImage(data.overview)!}
          />,
          <Blurb
            key="2"
            title="Easy to integrate"
            text="Swarmia works with your internal systems as well as the the tools your engineering teams already use."
            isLarge
            image={getImage(data.integrations)!}
          />,
        ]}
      />
      <ColumnsBlock
        paddingBottom={responsiveScale(64)}
        columns={[
          <Blurb
            key="1"
            icon={GearIcon}
            title="Flexible set up and configuration"
            text="Setting up Swarmia is easy. You can either do it yourself or get support from our customer success team — at no extra cost."
          />,
          <Blurb
            key="2"
            icon={AllWorkIcon}
            title="Roll out at your own pace"
            text="Your dedicated customer success manager will work with your team to tailor onboarding and rollout."
          />,
          <Blurb
            key="3"
            icon={LockIcon}
            title="Designed security-first"
            text="Swarmia is SOC 2 Type 2 and GDPR compliant. We perform thorough security audits twice a year."
          />,
        ]}
      />
      <Box
        // This is needed for the FAQ styles to work
        className="main-body-old-styles"
      >
        <FAQ />
      </Box>
      <Box paddingBottom={responsiveScale(64)}>
        <CTABlock
          title="Ready to start combining developer feedback with healthy engineering metrics?"
          secondaryButton={null}
        />
        <FeaturedBlogArticles
          heading="More from the swarmia blog"
          articles={[
            '/blog/developer-survey-questions/',
            '/blog/minimizing-noise-and-bias-in-developer-surveys/',
            '/blog/measuring-software-development-productivity/',
          ]}
          newStyles
        />
      </Box>
    </Layout>
  )
}

export default DXPage
